'use client';

import React from 'react';
import styled from 'styled-components';
import { BannerHybridSize } from '@printi/ds-offset-react-core';
import { BannerHybrid } from '../../../../../../packages/promotions/src/components';
import { IAttributesBannerHome } from '../../../../../../packages/promotions/src/repositories/aecomStrapiCms/bannerHome/types';
import { useLogManager } from '../../../../../../packages/common/src/components/Logs/LogManager.hooks';

const Container = styled.div`
  width: 100%;
  cursor: pointer;
`;

enum BannerType {
  Hero = 'Banner Hero',
  Common = 'Banner Comum',
}

export function HomeV2BannerHybrid({
  banner,
  bannerId,
  isHeroBanner = false,
}: {
  banner: IAttributesBannerHome | undefined;
  bannerId: number | null;
  isHeroBanner?: boolean;
}) {
  const { trackEvent } = useLogManager();
  const imageOnlyMode = banner?.isImageOnlyBanner ?? undefined;
  const showBanner = banner?.displayAtHomePage ?? isHeroBanner;

  const bannerImages = {
    large: banner?.bannerLG?.data?.attributes?.url || '',
    medium: banner?.bannerMD?.data?.attributes?.url || '',
    small: banner?.bannerSM?.data?.attributes?.url || '',
  };

  const bannerTexts = {
    alt: banner?.bannerImageAlt || 'Banner',
    title: banner?.bannerTitle || '',
    description: banner?.bannerDescription || '',
    actionUrl: banner?.promotion?.[0]?.href || '#',
    actionTarget: banner?.promotion?.[0]?.target || '_self',
    actionLabel: banner?.actionLabel || '',
  };

  const handleBannerClick = () => {
    if (!banner) {
      return;
    }

    const bannerType = banner.bannerType[0] as BannerType | undefined;
    const detailsEvent = {
      creative_name: bannerTexts.title,
      creative_slot: bannerType,
      promotion_id: bannerId,
      promotion_name: bannerTexts.alt,
      banner_type: bannerType,
    };

    const allowedTypes: BannerType[] = [BannerType.Hero, BannerType.Common];

    if (bannerType && allowedTypes.includes(bannerType)) {
      trackEvent('view_promotion', detailsEvent);
      window.open(bannerTexts.actionUrl, bannerTexts.actionTarget);
    }
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Container onClick={handleBannerClick}>
      <BannerHybrid
        bannerId={bannerId}
        imageOnlyMode={imageOnlyMode}
        imageLg={bannerImages.large}
        imageMd={bannerImages.medium}
        imageSm={bannerImages.small}
        imageAlt={bannerTexts.alt}
        imageTitle={bannerTexts.alt}
        heading={bannerTexts.title}
        description={bannerTexts.description}
        actionUrl={bannerTexts.actionUrl}
        actionLabel={bannerTexts.actionLabel}
        actionTarget={bannerTexts.actionTarget}
        bannerImageAlt={bannerTexts.alt}
        bannerImage={bannerImages.large}
        size={BannerHybridSize.LG}
      />
    </Container>
  );
}
